import React from "react";
import Layout from "../components/layout";
import JSONData from "../../content/interests.json";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const CompDesc = ({ props }) => {
	return <div>
		{(props && props.length > 0) ?
			props.map((content, index) => {
				return <p className="para" key={index} >{content}</p>;
			})
			: null
		}
	</div>;
};

const Interests = () => {
	const { isAuthenticated } = useAuth0();

	return (
		isAuthenticated && (
			<Layout title="Interests">
				<div className="int-subtitle">{ JSONData.subtitle }</div>
				{JSONData.content.map((item, index) => ( 
					<div className="int-project" key={index}>
						<div className="int-project-title">{item.project}</div>
						<div className="int-project-link"><a href={item.link}>{item.link}</a></div>
						<div className="int-project-desc"><CompDesc props={item.desc} /></div>
					</div> 
				))}
			</Layout>
		)
	);
};

export default withAuthenticationRequired(Interests);
